<template>
  <b-container fluid>
    <b-row>
      <b-col md="3">
        <iq-card>
          <template v-slot:body>
            <b-nav pills fill vertical>
              <h4 class="border-bottom mb-3">{{ $t(title) }}</h4>
              <b-nav-item v-for="(item,index) in menuItems" :key="index"
                          :to="{ name: item.routeName }" exact exact-active-class="active">
                <font-awesome-icon :icon="item.icon" /> {{ $t(item.displayName) }}
              </b-nav-item>
            </b-nav>
          </template>
        </iq-card>
      </b-col>
      <b-col md="9">
        <router-view :key="$router.currentRoute.meta.childName"></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import layoutService from '../../../services/layout'

  export default {
    name: 'LeftMenu',
    props: {
      dataUrl: String,
      startRoute: String,
      title: String
    },
    data() {
      return {
        menuItems: []
      };
    },
    methods: {
      getComponent: function () {
        this.menuItems = [];
        layoutService.getLeftMenuItems(this.dataUrl)
          .then(response => {
            this.menuItems = response;
            if (this.startRoute && this.startRoute.length > 0 && (this.$router.currentRoute.name == 'settings' || this.$router.currentRoute.name == 'definitions'))
              this.$router.push({ name: this.startRoute });
          });
      }
    },
    watch: {
      dataUrl: function () {
        this.getComponent();
      }
    },
    mounted: function () {
      this.getComponent();
    }
  }
</script>
